/* global document */
import naja from 'naja';

function initHistogram() {
	const bars = document.querySelectorAll('.js-bar');
	const maxHeight = 3.125; // TODO units.px2rem(50px);
	const minHeight = 0.5;
	const maxBonusHeight = maxHeight - minHeight;
	let maxValue = 0;
	bars.forEach((bar) => {
		const adsCount = Math.sqrt(parseInt(bar.dataset.adsCount, 10));
		if (adsCount > maxValue) {
			maxValue = adsCount;
		}
	});

	bars.forEach((bar) => {
		// eslint-disable-next-line no-param-reassign
		bar.style.height = `${Math.sqrt(bar.dataset.adsCount) * (maxBonusHeight / maxValue) + minHeight}rem`;
	});
}

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
	const { snippet } = event.detail;
	if (snippet.id === 'snippet--searchForm') {
		initHistogram();
	}
});

initHistogram();
