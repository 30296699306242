/* global document IntersectionObserver */

const lists = document.querySelectorAll('.js-list');

if (lists) {
	lists.forEach((list) => {
		const prevArrow = document.querySelector('.js-prev-arrow');
		const nextArrow = document.querySelector('.js-next-arrow');
		const { children } = list;
		const GAP_SPACING = parseInt(list.dataset.spacing, 10) ?? 10;
		const listOffsetClass = 'availability__list--offset';
		const noOffset = list.dataset.offset;
		let currentPosition = 0;
		let currentIndex = 0;
		let isDown = false;
		let startX;
		let scrollLeft;

		// eslint-disable-next-line no-inner-declarations
		function init() {
			const arr = [...children];

			if (arr.length > 0) {
				arr.forEach((item, index) => {
					item.setAttribute('data-slide', index);
				});
			}
		}

		// eslint-disable-next-line no-inner-declarations
		function calculateOffset() {
			setTimeout(() => {
				if (currentIndex !== 0 && !noOffset) {
					list.classList.add(listOffsetClass);
				} else {
					list.classList.remove(listOffsetClass);
				}
			}, 100);

			list.scroll({
				behavior: 'smooth',
				left: currentPosition,
				top: 0,
			});
		}

		// eslint-disable-next-line no-inner-declarations
		function recalculatePosition() {
			let offset = 0;
			const arr = [...children];
			arr.slice(0, currentIndex).forEach((item) => {
				offset += +((item.getBoundingClientRect().width + GAP_SPACING).toFixed(2));
			});
			currentPosition = offset;
		}

		prevArrow.addEventListener('click', ((e) => {
			e.preventDefault();
			if (currentIndex > 0) {
				recalculatePosition();
				currentIndex -= 1;
				currentPosition -= +((children[currentIndex].getBoundingClientRect().width + GAP_SPACING).toFixed(2));
				calculateOffset();
			}
		}));

		nextArrow.addEventListener('click', ((e) => {
			e.preventDefault();
			if (currentIndex < children.length) {
				recalculatePosition();
				currentPosition += +((children[currentIndex].getBoundingClientRect().width + GAP_SPACING).toFixed(2));
				currentIndex += 1;
				calculateOffset();
			}
		}));

		// eslint-disable-next-line no-inner-declarations
		function checkArrows() {
			if (Math.round(list.scrollLeft + list.clientWidth) >= (list.scrollWidth)) {
				nextArrow.classList.add('is-hidden');
			} else {
				nextArrow.classList.remove('is-hidden');
			}
			if (list.scrollLeft > 0) {
				prevArrow.classList.remove('is-hidden');
			} else {
				prevArrow.classList.add('is-hidden');
			}
		}

		list.addEventListener('scroll', () => checkArrows());

		// eslint-disable-next-line no-inner-declarations
		function end() {
			isDown = false;
			list.classList.remove('is-drag');
		}

		// eslint-disable-next-line no-inner-declarations
		function start(e) {
			isDown = true;
			startX = e.pageX || e.touches[0].pageX - list.offsetLeft;
			scrollLeft = list.scrollLeft;
		}

		// eslint-disable-next-line no-inner-declarations
		function move(e) {
			if (!isDown) return;

			e.preventDefault();
			list.classList.add('is-drag');
			const x = e.pageX || e.touches[0].pageX - list.offsetLeft;
			const dist = (x - startX);
			// eslint-disable-next-line no-param-reassign
			list.scrollLeft = scrollLeft - dist;
		}

		list.addEventListener('mousedown', start);
		list.addEventListener('touchstart', start);

		list.addEventListener('mousemove', move);
		list.addEventListener('touchmove', move);

		list.addEventListener('mouseleave', end);
		list.addEventListener('mouseup', end);
		list.addEventListener('touchend', end);

		const slideObserver = new IntersectionObserver(
			((entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						currentIndex = +(entry.target.dataset.slide);
					}
				});
			}),
			{
				root: document.querySelector('.js-list-wrap'),
				rootMargin: '0px -80% 0px 0px',
				threshold: 0.55,
			},
		);

		const items = document.querySelectorAll('.js-list-item');
		items.forEach((item) => slideObserver.observe(item));

		init();
	});
}
