/* global document */

const footerAccordions = document.querySelectorAll('.js-footer-accordion');
const accordionClosed = 'footer__accordion--closed';
const panelClosed = 'footer__panel--open';
const accordionActive = 'footer__accordion--active';

footerAccordions.forEach((accordion) => {
	accordion.addEventListener('click', (e) => {
		e.preventDefault();
		const panel = accordion.parentElement.querySelector('.js-footer-accordion-panel');
		const wrap = accordion.closest('.js-footer-accordion-wrap');
		if (panel) {
			panel.classList.toggle(panelClosed);
			accordion.classList.toggle(accordionClosed);
			wrap.classList.toggle(accordionActive);
		}
	});
});
