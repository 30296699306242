/* global document */
import naja from 'naja';

const accordionClosed = 'filter__accordion--closed';
const panelClosed = 'filter__tag-wrap--closed';
const tagWrap = 'filter__tag-wrap--limited';
const visuallyHidden = 'visually-hidden';
let filterReveal = document.querySelectorAll('.js-filter-reveal');
let filterAccordions = document.querySelectorAll('.js-filter-accordion');
let snippetAccordions;
let snippetReveal;

function init() {
	filterAccordions.forEach((accordion) => {
		accordion.addEventListener('click', (e) => {
			e.preventDefault();
			const panel = accordion.parentElement.querySelector('.js-filter-accordion-panel');
			if (panel) {
				panel.classList.toggle(panelClosed);
				accordion.classList.toggle(accordionClosed);
			}
		});
	});

	filterReveal.forEach((reveal) => {
		reveal.addEventListener('click', (e) => {
			e.preventDefault();
			const parent = reveal.parentElement;
			if (parent) {
				parent.classList.remove(tagWrap);
				reveal.classList.add(visuallyHidden);
			}
		});
	});
}

function checkAccordions(data) {
	data.forEach((accordion, i) => {
		if (accordion &&	accordion.classList.contains(accordionClosed)) {
			filterAccordions[i].classList.add(accordionClosed);
			const parent = filterAccordions[i].parentElement;
			const panel = parent.querySelector('.filter__tag-wrap');
			panel.classList.add(panelClosed);
		}
	});
}

function checkReveal(data) {
	data.forEach((reveal, i) => {
		if (reveal && reveal.classList.contains(visuallyHidden)) {
			filterReveal[i].classList.add(visuallyHidden);
			const parent = filterReveal[i].parentElement;
			parent.classList.remove(tagWrap);
		}
	});
}

const source = document.getElementById('snippet--searchForm');
const modalAppend = document.querySelector('.js-modal-append');
const layoutAppend = document.querySelector('.js-layout-append');
const openModal = document.querySelector('.js-open-mobile');

openModal.addEventListener('click', (e) => {
	e.preventDefault();
	modalAppend.prepend(source);
	const modalData = document.getElementById(openModal.dataset.modal);
	const exits = modalData.querySelectorAll('[data-dismiss]');
	if (exits) {
		exits.forEach((exit) => {
			exit.addEventListener('click', (event) => {
				event.preventDefault();
				layoutAppend.prepend(source);
			});
		});
	}
});

naja.snippetHandler.addEventListener('beforeUpdate', (event) => {
	const { snippet } = event.detail;
	if (snippet.id === 'snippet--searchForm') {
		snippetAccordions = snippet.querySelectorAll('.js-filter-accordion');
		snippetReveal = snippet.querySelectorAll('.js-filter-reveal');
	}
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
	const { snippet } = event.detail;
	if (snippet.id === 'snippet--searchForm') {
		filterAccordions = snippet.querySelectorAll('.js-filter-accordion');
		filterReveal = snippet.querySelectorAll('.js-filter-reveal');
		checkAccordions(snippetAccordions);
		checkReveal(snippetReveal);
		init();
	}

	// Relay click event to correct button in order to close modal
	if (snippet.id === 'snippet--modalButton') {
		const exits = snippet.querySelectorAll('[data-dismiss]');
		if (exits) {
			exits.forEach((exit) => {
				exit.addEventListener('click', (exitEvent) => {
					const dismiss = document.querySelector('.modal-exit');
					exitEvent.preventDefault();
					dismiss.click();
				});
			});
		}
	}
});
init();
