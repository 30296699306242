/* global document, window, dataLayer */
import 'vanilla-cookieconsent';
import naja from 'naja';

import { getCookie } from './cookieHandler';

window.addEventListener('load', () => {
	const cookieconsent = window.initCookieConsent();
	let skipAction = false;
	cookieconsent.run({
		autorun: true,
		current_lang: 'cz',
		theme_css: '',
		autoclear_cookies: false,
		page_scripts: true,
		force_consent: true,

		gui_options: {
			consent_modal: {
				layout: 'box',
				position: 'middle center',
				transition: 'slide',
				swap_buttons: true,
			},
			settings_modal: {
				layout: 'box',
				transition: 'slide',
			},
		},

		onAccept(cookie) {
			if (!skipAction) {
				dataLayer.push({
					event: 'cookie_consent',
					ad_storage: cookie.level.includes('ad') ? 'granted' : 'denied',
					analytics_storage: cookie.level.includes('analytics') ? 'granted' : 'denied',
					functionality_storage: cookie.level.includes('personalization') ? 'granted' : 'denied',
					personalization_storage: cookie.level.includes('personalization') ? 'granted' : 'denied',
					security_storage: 'granted',
				});
			}
		},

		onChange(cookie) {
			naja.makeRequest(
				'POST',
				document.querySelector('body').dataset.url,
				{
					data: {
						uuid: (cookieconsent.get('data') !== null && typeof cookieconsent.get('data') !== 'undefined')
							? cookieconsent.get('data').uuid : null,
						essential: cookie.level.includes('necessary'),
						preference: cookie.level.includes('personalization'),
						marketing: cookie.level.includes('ad'),
						analytics: cookie.level.includes('analytics'),
					},
				},
				{ history: false },
			).then((responseData) => {
				if (Object.prototype.hasOwnProperty.call(responseData, 'uuid')
					&& (cookieconsent.get('data') === null
						|| typeof cookieconsent.get('data') === 'undefined'
						|| typeof cookieconsent.get('data').uuid === 'undefined')) {
					cookieconsent.set('data', { value: { uuid: responseData.uuid } });
				}
			});

			dataLayer.push({
				event: 'cookie_update',
				ad_storage: cookie.level.includes('ad') ? 'granted' : 'denied',
				analytics_storage: cookie.level.includes('analytics') ? 'granted' : 'denied',
				functionality_storage: cookie.level.includes('personalization') ? 'granted' : 'denied',
				personalization_storage: cookie.level.includes('personalization') ? 'granted' : 'denied',
				security_storage: 'granted',
			});
		},

		onFirstAction(userPreferences) {
			naja.makeRequest(
				'POST',
				document.querySelector('body').dataset.url,
				{
					data: {
						essential: userPreferences.accepted_categories.includes('necessary'),
						preference: userPreferences.accepted_categories.includes('personalization'),
						marketing: userPreferences.accepted_categories.includes('ad'),
						analytics: userPreferences.accepted_categories.includes('analytics'),
					},
				},
				{ history: false },
			).then((responseData) => {
				if (Object.prototype.hasOwnProperty.call(responseData, 'uuid')) {
					cookieconsent.set('data', { value: { uuid: responseData.uuid } });
				}
			});

			skipAction = true;
			dataLayer.push({
				event: 'cookie_update',
				ad_storage: userPreferences.accepted_categories.includes('ad') ? 'granted' : 'denied',
				analytics_storage: userPreferences.accepted_categories.includes('analytics') ? 'granted' : 'denied',
				functionality_storage: userPreferences.accepted_categories.includes('personalization') ? 'granted' : 'denied',
				personalization_storage: userPreferences.accepted_categories.includes('personalization') ? 'granted' : 'denied',
				security_storage: 'granted',
			});
		},

		languages: {
			cz: {
				consent_modal: {
					title: 'Tato webová stránka používá cookies. Povolit v prohlížeči použití souborů cookies?',
					description: '<strong>Soubory cookies a&nbsp;podobné technologie využíváme k&nbsp;následujícím účelům:</strong>'
						+ '<ul><li>Poskytování a&nbsp;vylepšování obsahu produktů nabízených na této webové stránce.</li>'
						+ '<li>Zvyšování zabezpečení díky informacím ze souborů cookies.</li>'
						+ '<li>Přizpůsobení obsahu a&nbsp;služeb, včetně reklam, návštěníkům této webové stránky.</li>'
						+ '<li>Kontaktování návštěvníků za účelem marketingových nabídek.</li></ul>'
						+ '<div class="text--center"><button type="button" class="btn btn--modal btn--secondary js-show-more display-sm">Více</button></div>'
						+ '<p class="display-sm-none js-show">Nástroje jiných společností používáme na této webové stránce k&nbsp;inzerci a&nbsp;k&nbsp;měření návštěvnosti '
						+ 've vztahu k&nbsp;produktům, k&nbsp;analýzám trhu produktů nabízených na této webové stránce a&nbsp;abychom mohli '
						+ 'poskytovat určité funkce a&nbsp;vylepšovat naše služby. Tyto společnosti také používají soubory cookies. '
						+ 'Tyto společnosti mohou údaje získané v&nbsp;této souvislosti zkombinovat s&nbsp;dalšími informacemi, které jste jim	'
						+ 'poskytli nebo které získali v&nbsp;důsledku toho, že používáte jejich služby. '
						+ '<a href="https://bigmedia.cz/zasady-zpracovani-osobnich-udaju" rel="nofollow noreferrer" aria-label="Zásady zpracování osobních údajů" target="_blank">Seznam společností.</a></p>'
						+ '<p class="display-sm-none js-show">Můžete povolit, aby se v&nbsp;tomto prohlížeči používaly všechny soubory cookies, nebo '
						+ 'si vybrat z&nbsp;jiných možností. Více se o&nbsp;souborech cookies a&nbsp;o&nbsp;tom, jak je používáme, '
						+ 'dozvíte v&nbsp;<a href="#" data-cc="c-settings">podrobnostech</a>, kde se také můžete kdykoli podívat na '
						+ 'svoje předvolby a&nbsp;případně je změnit.</p>',
					primary_btn: {
						text: 'Souhlasím',
						role: 'accept_all',
					},
					secondary_btn: {
						text: 'Nastavení',
						role: 'settings',
					},
				},
				settings_modal: {
					title: 'Podrobnosti o cookies',
					save_settings_btn: 'Uložit nastavení',
					accept_all_btn: 'Vše povolit',
					reject_all_btn: 'Vše zamítnout',
					cookie_table_headers: [
						{ col1: 'Name' },
					],
					blocks: [
						{
							description: 'Soubory cookies jsou malé textové sobory uložené ve vašem nastavení, '
								+ 'které lze číst pomocí webového serveru na doméně, která soubor cookies umístila. '
								+ 'Soubory cookies a\u00a0podobné technologie používání k\u00a0ukládání a\u00a0zapamatování vašich předvoleb a\u00a0nastavení, '
								+ 'což usnadňuje přihlašování, poskytování reklam založených na zájmech, zvýšení bezpečnosti, '
								+ 'analýzu výkonu našich produktů a\u00a0naplňování dalších oprávněných účelů.',
							title: 'Soubory cookies a\u00a0podobné technologie využíváme k\u00a0následujícím účelům:',
						}, {
							title: 'Naprosto nezbytné soubory <a href="https://bigmedia.cz/zasady-pouziti-sitovych-identifikatoru" rel="nofollow noreferrer" aria-label="Zásady použití síťových identifikátorů" target="_blank">cookies</a>',
							description: 'Pomáhají, aby webová stránka byla použitelná tak, že umožní základní funkce jako navigace '
								+ 'stránky a\u00a0přístup k\u00a0zabezpečeným sekcím webové stránky. '
								+ 'Webová stránka nemůže bez těchto cookies správně fungovat.',
							toggle: {
								value: 'necessary',
								enabled: true,
								readonly: true,
							},
						}, {
							title: 'Preferenční <a href="https://bigmedia.cz/zasady-pouziti-sitovych-identifikatoru" rel="nofollow noreferrer" aria-label="Zásady použití síťových identifikátorů" target="_blank">cookies</a>',
							description: 'Tyto cookies umožňují, aby si webová stránka zapamatovala informace '
								+ 'jak se webová stránka mění, jak se chová a\u00a0vypadá dle '
								+ 'vašeho nastavení, např. Preferovaný jazyk, region atd.',
							toggle: {
								value: 'personalization',
								enabled: false,
								readonly: false,
							},
						}, {
							title: 'Marketingové <a href="https://bigmedia.cz/zasady-pouziti-sitovych-identifikatoru" rel="nofollow noreferrer" aria-label="Zásady použití síťových identifikátorů" target="_blank">cookies</a>',
							description: 'Tyto cookies jsou používány pro sledování návštěvníků na webových stránkách. '
								+ 'Záměrem je zobrazit reklamu, která je relevantní a\u00a0zajímavá pro jednotlivého návštěvníka '
								+ 'a\u00a0tímto hodnotnější pro naši společnost a\u00a0inzerenty třetích stran. \n'
								+ 'Tyto cookies umožňují kontaktovat návštěvníky za účelem marketingových nabídek.',
							toggle: {
								value: 'ad',
								enabled: false,
								readonly: false,
							},
							cookie_table: [
								{
									col1: '_fbp',
									is_regex: true,
								},
								{
									col1: '_gcl_au',
									is_regex: true,
								},
								{
									col1: 'ads/ga-audiences',
									is_regex: true,
								},
								{
									col1: 'leady_tab_id',
									is_regex: true,
								},
								{
									col1: 'leady_session_id',
									is_regex: true,
								},
								{
									col1: 'pagead/landing',
									is_regex: true,
								},
								{
									col1: 'retargeting',
									is_regex: true,
								},
								{
									col1: 'tr',
									is_regex: true,
								},
							],
						}, {
							title: 'Statistické <a href="https://bigmedia.cz/zasady-pouziti-sitovych-identifikatoru" rel="nofollow noreferrer" aria-label="Zásady použití síťových identifikátorů" target="_blank">cookies</a>',
							description: 'Tyto cookies pomáhají majitelům webových stránek, aby porozuměli, jak návštěvníci '
								+ 'používají webové stránky. Anonymně sbírají a\u00a0sdělují informace.',
							toggle: {
								value: 'analytics',
								enabled: false,
								readonly: false,
							},
							cookie_table: [
								{
									col1: '^_ga',
									is_regex: true,
								},
								{
									col1: '_gid',
									is_regex: true,
								},
								{
									col1: '_ga_BKMY6LS45H',
									is_regex: true,
								},
								{
									col1: 'collect',
									is_regex: true,
								},
							],
						},
					],
				},
			},
		},
	});
	const cookies = document.getElementById('cc_div');
	cookies.classList.add('cc_custom');

	if (getCookie('cc_cookie').length <= 0) {
		dataLayer.push({
			event: 'cookie_consent',
			ad_storage: 'denied',
			analytics_storage: 'denied',
			functionality_storage: 'denied',
			personalization_storage: 'denied',
			security_storage: 'granted',
		});
	}

	const showButton = cookies.querySelector('.js-show-more');
	const showElements = cookies.querySelectorAll('.js-show');
	const displayClass = 'display-sm-none';

	if (showButton) {
		showButton.addEventListener('click', () => {
			if (showElements) {
				showElements.forEach((element) => {
					element.classList.remove(displayClass);
					showButton.classList.add('visually-hidden');
				});
			}
		});
	}
});
