/* global document, SMap, JAK, navigator, initMapData, initCartData, sessionStorage, window, dataLayer */
import '..';
import naja from 'naja';
import Sticky from 'sticky-js';
import rangeSlider from 'range-slider-input';

import { getCookie } from '../js/cookieHandler';

import '../js/dropdown';
import '../js/filters';
import '../js/histogram';

const publicURL = (process.env.PUBLIC_URL !== null ? `${process.env.PUBLIC_URL}` : 'http://plakatov.cz/');
let gtmLoaded = false;

document.addEventListener('GTMload', () => {
	gtmLoaded = true;
});

const stickyMap = new Sticky('.js-sticky-map');
stickyMap.update();

const center = SMap.Coords.fromWGS84(15.3387, 49.7438);
const map = new SMap(JAK.gel('mapa'), center, 7, { minZoom: 6 });

map.addDefaultLayer(SMap.DEF_BASE).enable();

// eslint-disable-next-line no-bitwise
map.addControl(new SMap.Control.Mouse(SMap.MOUSE_PAN | SMap.MOUSE_ZOOM | SMap.MOUSE_WHEEL));

const sync = new SMap.Control.Sync();
map.addControl(sync);

const zoomControl = new SMap.Control.Zoom();
map.addControl(zoomControl);

const markersLayer = new SMap.Layer.Marker();
map.addLayer(markersLayer).enable();

let clusterer = new SMap.Marker.Clusterer(map);

markersLayer.setClusterer(clusterer);
let markers = [];
const markersCoords = [];
let markersInCart;
let cartDataFromPayload;

let visitedCookies = getCookie('plakatov_visited_ads');

function drawMarkers(newMapData = initMapData) {
	markersLayer.removeAll();
	markers = [];

	newMapData.forEach((marker) => {
		const markerLocation = SMap.Coords.fromWGS84(marker.vgsLongitude, marker.vgsLatitude);

		const markerIcon = JAK.mel('div');
		markerIcon.classList.add('map__marker');
		if (markersInCart && markersInCart.includes(marker.id)) {
			markerIcon.classList.add('map__marker--cart');
		} else if (visitedCookies && visitedCookies.includes(marker.id.toString())) {
			markerIcon.classList.add('map__marker--visited');
		}

		if (marker.exactMatch === '0') {
			markerIcon.classList.add('map__marker--approximate');
		}

		markerIcon.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="48" viewBox="0 0 32 48">'
			+ '<path fill="currentColor" d="M22 16a6 6 0 1 1-12 0 6 6 0 0 1 12 0zm-6 32c.9-1.4 1.7-2.8 2.6-4C26.4 31.5 32 22.7 32 '
			+ '15.1c0-2-.4-4-1.3-6A15.5 15.5 0 0 0 27 4.6a16.6 16.6 0 0 0-5-3.3A16 16 0 0 0 16 0c-2 0-4 .4-6 1.2a16.6 16.6 0 0 '
			+ '0-5.1	3.3 15.5 15.5 0 0 0-3.6 4.8A14 14 0 0 0 0 15.2c0 7.6 5.6 16.4 13.4 28.7L16 48z"/></svg>';

		const markerRender = new SMap.Marker(markerLocation, marker.id, {
			title: marker.id,
			url: markerIcon,
		});
		markers.push(markerRender);
		markersCoords.push(markerLocation);
	});
	markersLayer.addMarker(markers);
}

function zoomToData(zoom) {
	const zoomMarkersCoords = [];
	zoom.forEach((zoomMarker) => {
		const zoomMarkerLocation = SMap.Coords.fromWGS84(zoomMarker.vgsLongitude, zoomMarker.vgsLatitude);
		zoomMarkersCoords.push(zoomMarkerLocation);
	});
	const [newCenter, newZoom] = map.computeCenterZoom(zoomMarkersCoords);
	map.setCenterZoom(newCenter, newZoom);
	sessionStorage.setItem('mapPrevZoom', newZoom);
}

function cardBorders() {
	const cards = document.querySelectorAll('.js-map-card');
	cards.forEach((card) => {
		if (markersInCart && markersInCart.includes(parseInt(card.dataset.id, 10))) {
			card.classList.add('product--highlight-cart');
		} else if (visitedCookies && visitedCookies.includes(card.dataset.id)) {
			card.classList.add('product--highlight-visited');
		}
	});
}

markersInCart = initCartData;
drawMarkers();
cardBorders();

let mapCardActiveId;
let mapCardActive;

function cardActive(id = mapCardActiveId) {
	const mapCard = document.querySelector(`.product[data-id="${id}"]`);
	if (mapCard) {
		mapCard.classList.add('product--highlight');
		mapCard.scrollIntoView({ behavior: 'smooth', block: 'center' });
	}
}

function cardDeactivate() {
	if (mapCardActive) mapCardActive.classList.remove('product--highlight');
}

function syncFavorite(button, reverse) {
	let isFavorite = button.classList.contains('product__favourite--active') || button.classList.contains('is-active');
	if (reverse === true) {
		isFavorite = !isFavorite;
	}
	const target = document.querySelector(button.dataset.target);
	if (target !== null) {
		if (isFavorite === true) {
			target.classList.add('product__favourite--active');
			target.classList.add('is-active');
			if (target.href.indexOf('add') !== -1) {
				target.href = target.href.replace('add', 'remove');
			}
		} else {
			target.classList.remove('product__favourite--active');
			target.classList.remove('is-active');
			if (target.href.indexOf('remove') !== -1) {
				target.href = target.href.replace('remove', 'add');
			}
		}
	}
}

map.getSignals().addListener(this, 'marker-click', (e) => {
	const marker = e.target;

	if (typeof marker.getMarkers !== 'undefined') {
		return;
	}
	map.setCenter(marker.getCoords(), true);

	const id = marker.getId();
	const storedRequestElement = document.querySelector('.filter__container');
	const url = `${publicURL}/ad/get-ad-place/${id}?storedRequest=${storedRequestElement.dataset.back}`;

	naja.makeRequest('POST', url, null, { history: false, unique: 'cardData' }).then((data) => {
		const card = new SMap.Card();
		const coords = marker.getCoords();

		card.getHeader().innerHTML = data.headerHtml;
		card.getBody().innerHTML = data.bodyHtml;
		map.addCard(card, SMap.Coords.fromWGS84(coords.x, coords.y));

		const cardFavoriteBtn = document.querySelector('.js-card-favorite');
		if (cardFavoriteBtn !== null) {
			cardFavoriteBtn.addEventListener('click', (cardFavoriteEvent) => {
				cardFavoriteEvent.preventDefault();
				cardFavoriteBtn.classList.toggle('product__favourite--active');
				// cardFavoriteBtn.classList.toggle('is-active');
				syncFavorite(cardFavoriteBtn, false);
				naja.makeRequest('POST', cardFavoriteBtn.href, null, { history: false, unique: 'cardFavorite' });
			});
		}

		const cardModalOpenBtn = document.querySelector('.js-card-modal-open');
		if (cardModalOpenBtn !== null) {
			cardModalOpenBtn.addEventListener('click', (cardFavoriteEvent) => {
				cardFavoriteEvent.preventDefault();
				naja.makeRequest('POST', cardModalOpenBtn.href, null, { history: false, unique: 'cardFavorite' });
			});
		}

		const mapCard = document.querySelector(`.product[data-id="${id}"]`);
		mapCardActive = document.querySelector('.product--highlight');
		if (mapCard) {
			cardDeactivate();
			cardActive(id);
			mapCardActiveId = id;
		}

		map.getSignals().addListener(this, 'map-click', () => {
			if (map.getCard() && !card.getContainer().contains(e.target.node)) {
				mapCardActive = document.querySelector('.product--highlight');
				map.removeCard();
				mapCardActiveId = '';
				cardDeactivate();
			}
		});
	});
});

let cardListLoader;
let cardList;
let cardListOther;
let form;
let searchLat;
let searchLon;
let searchViewport;
let searchMoved;
let searching;
let priceFrom;
let priceTo;
let searchLimit;
let otherSearchLimit;
let btnLoader;
const filterTypeSelected = [];

let suggesting = false;
let lastRequestZoomOut = false;

let barSticky;
const isFilterSticky = () => document.querySelector('.js-filter').stickyData !== undefined;

function stickyBarOpen() {
	barSticky.elements.forEach((barElement) => {
		// eslint-disable-next-line no-param-reassign
		barElement.style.top = `${barElement.dataset.marginTopAlt}px`;
	});
}

function stickyBarClose() {
	barSticky.elements.forEach((barElement) => {
		// eslint-disable-next-line no-param-reassign
		barElement.style.top = `${barElement.dataset.marginTop}px`;
	});
}

function unstickFilter() {
	const filter = document.querySelector('.js-filter');

	if (filter.stickyData !== undefined) {
		filter.stickyData.destroy();
		delete filter.stickyData;
	}

	stickyBarClose();
	filter.removeAttribute('style');
	filter.classList.remove('filter--sticky');

	window.removeEventListener('scroll', unstickFilter);
}

function stickFilter() {
	const filter = document.querySelector('.js-filter');

	if (filter.stickyData !== undefined) {
		return;
	}

	stickyBarOpen();

	filter.classList.add('filter--sticky');
	filter.style.width = `${filter.parentElement.offsetWidth}px`;

	window.addEventListener('scroll', unstickFilter, { once: true });
}

function initLoader() {
	btnLoader	= document.querySelector('.js-btn-loader');
	cardListLoader = document.querySelectorAll('.js-card-list-loader');
	cardList = document.querySelector('.js-card-list');
	cardListOther = document.querySelector('.js-card-other-list');
}

function showLoader(shouldHideCards = false) {
	initLoader();
	if (cardListLoader && cardListOther) {
		cardListLoader.forEach((loader) => {
			loader.classList.add('is-active');
		});
		if (shouldHideCards) {
			cardListOther.classList.add('is-hidden');
		}
	} else if (cardListLoader && cardList) {
		cardListLoader.forEach((loader) => {
			loader.classList.add('is-active');
		});
		if (shouldHideCards) {
			cardList.classList.add('is-hidden');
		}
	}

	if (btnLoader) {
		btnLoader.classList.add('is-loading');
		btnLoader.disabled = true;
	}
}

function hideLoader() {
	initLoader();
	if (cardListLoader && cardList) {
		cardListLoader.forEach((loader) => {
			loader.classList.remove('is-active');
		});
		cardList.classList.remove('is-hidden');
	}
	if (btnLoader) {
		btnLoader.classList.remove('is-loading');
		btnLoader.disabled = false;
	}
}

function updateMinimized() {
	const layout = document.querySelector('.js-layout-append');
	const filter = document.querySelector('.js-filter');
	if (filter.dataset.minimized === '1') {
		layout.classList.add('ad-layout__filters--minimized');
	} else {
		layout.classList.remove('ad-layout__filters--minimized');
	}
}

function reloadPinsFromPayload(payload) {
	if (!payload.filteredMapData) {
		return;
	}
	const { filteredMapData } = payload;
	drawMarkers(filteredMapData);
}

function zoomToPayload(payload) {
	if (!payload.zoom) {
		return;
	}
	const { zoom } = payload;
	if (zoom.length > 0) {
		zoomToData(zoom);
	}
}

function searchFormSubmit(zoomMap = false, reloadPins = false, keepViewport = false, isSearching = false) {
	if (keepViewport === false) {
		searchViewport.value = null;
	}
	searchMoved.value = !zoomMap;
	searching.value = isSearching;
	const filterSticky = isFilterSticky();
	showLoader(true);

	naja.uiHandler.submitForm(form, { unique: 'form' }).then((payload) => {
		const { cartData } = payload;
		cartDataFromPayload = cartData;
		if (reloadPins === true) {
			reloadPinsFromPayload(payload);
		}
		if (zoomMap === true) {
			zoomToPayload(payload);
		}
	});
	if (filterSticky) {
		setTimeout(stickFilter, 100);
	}
	if (priceTo?.value !== '') {
		if (gtmLoaded) {
			dataLayer.push({
				event: 'gaEvent',
				gaEventData: {
					eCat: 'Filter used',
					eAct: 'Cena',
					eLab: `do ${priceTo.value}Kč/měs`,
					eVal: '',
					eNonInteraction: 'false',
				},
				eventCallback() {
					dataLayer.push({ gaEventData: undefined });
				},
			});
		}
	}
	if (filterTypeSelected.length > 0) {
		if (gtmLoaded) {
			dataLayer.push({
				event: 'gaEvent',
				gaEventData: {
					eCat: 'Filter used',
					eAct: 'Typ plochy',
					eLab: filterTypeSelected.join('|'),
					eVal: '',
					eNonInteraction: 'false',
				},
				eventCallback() {
					dataLayer.push({ gaEventData: undefined });
				},
			});
		}
	}
}

function sendSortGTM(value) {
	if (gtmLoaded) {
		dataLayer.push({
			event: 'gaEvent',
			gaEventData: {
				eCat: 'Sort Used',
				eAct: value,
				eLab: '',
				eVal: '',
				eNonInteraction: 'false',
			},
			eventCallback() {
				dataLayer.push({ gaEventData: undefined });
			},
		});
	}
}

function deCluster() {
	markersLayer.setClusterer(null);

	if (map.getZoom() < 14) {
		clusterer = new SMap.Marker.Clusterer(map);
		markersLayer.setClusterer(clusterer);
	}
}

deCluster();

function handleMapRedraw() {
	if (!lastRequestZoomOut) {
		searchLimit.value = 24;
		otherSearchLimit.value = 24;
	}
	lastRequestZoomOut = false;

	const viewport = map.getViewport();
	const isViewportValid = !(viewport.lbx === viewport.rtx || viewport.lby === viewport.rty);
	if (isViewportValid) {
		searchViewport.value = JSON.stringify(viewport);
	}
	if (!suggesting)	{
		searchFormSubmit(false, true, true);
	}
	suggesting = false;

	sessionStorage.setItem('mapPrevCenter', `${map.getCenter().x}, ${map.getCenter().y}`);
	sessionStorage.setItem('mapPrevZoom', map.getZoom());
	sessionStorage.setItem('backBtnClicked', false);
	if (isViewportValid) {
		sessionStorage.setItem('mapPrevViewport', JSON.stringify(map.getViewport()));
	}

	deCluster();

	const newVisitedCookies = getCookie('plakatov_visited_ads');
	const oldVisitedCookies = visitedCookies;
	if (newVisitedCookies.length > 0 && oldVisitedCookies.length === 0) {
		visitedCookies = newVisitedCookies;
		drawMarkers();
	} else if (newVisitedCookies.length > 0 && !newVisitedCookies.every((v, i) => v === oldVisitedCookies[i])) {
		visitedCookies = newVisitedCookies;
		drawMarkers();
	}

	if (cartDataFromPayload && typeof cartDataFromPayload !== 'undefined' && cartDataFromPayload.length > 0) {
		const newMarkersInCart = cartDataFromPayload.sort();
		const oldMarkersInCart = markersInCart.sort();
		if (!newMarkersInCart.every((v, i) => v === oldMarkersInCart[i])
			|| (newMarkersInCart.length === 0 && oldMarkersInCart.length > 0)) {
			markersInCart = newMarkersInCart;
			drawMarkers();
		}
	}
	cardBorders();
}

map.getSignals().addListener(this, 'map-redraw', () => {
	handleMapRedraw();
});

function resetSearch() {
	const search = document.querySelector('.js-search');
	search.value = '';
	searchFormSubmit();
	zoomToData(initMapData);
}

function initStickyBar() {
	barSticky = new Sticky('.js-sticky-filters');
	barSticky.update();
}

function init() {
	updateMinimized();
	initStickyBar();
	form = document.querySelector('.js-search-form');
	searchLat = document.querySelector('.js-search-lat');
	searchLon = document.querySelector('.js-search-lon');
	searchViewport = document.querySelector('.js-search-viewport');
	searchMoved = document.querySelector('.js-search-moved');
	searching = document.querySelector('.js-searching');
	searchLimit = document.querySelector('.js-search-limit');
	otherSearchLimit = document.querySelector('.js-other-search-limit');
	priceFrom = document.querySelector('.js-search-priceFrom');
	priceTo = document.querySelector('.js-search-priceTo');
	const filterType = document.querySelectorAll('.js-filter-type');
	const dateType = document.querySelectorAll('.js-filter-date');
	const filterLocation = document.querySelectorAll('.js-filter-location');
	const statusType = document.querySelectorAll('.js-filter-status');
	const sortType = document.querySelectorAll('.js-sort-link');
	const filterRegion = document.querySelectorAll('.js-filter-region');
	const searchInput = document.querySelector('.js-search-input');
	const searchHidden = document.querySelector('.js-search');
	const adID = document.querySelector('.js-search-adId');
	const numberPattern = /^[0-9]+$/i;

	if (searchInput) {
		searchInput.addEventListener('keyup', (e) => {
			if (
				e.keyCode === 13
				&& searchInput.value.length >= 6
				&& searchInput.value.length <= 8
				&& numberPattern.test(searchInput.value)
			) {
				e.preventDefault();
				adID.value = searchInput.value;
				searchInput.value = '';
				searchFormSubmit(true, true);
			}
		});
	}

	filterRegion.forEach((region) => {
		region.addEventListener('change', (e) => {
			e.preventDefault();
			adID.value = '';
			searchInput.value = '';
			searchHidden.value = '';
			searchFormSubmit(true, true, false);
		});
	});

	filterType.forEach((type) => {
		type.addEventListener('change', (e) => {
			e.preventDefault();
			adID.value = '';
			searchFormSubmit(false, true, true);
		});
	});

	dateType.forEach((type) => {
		type.addEventListener('change', (e) => {
			e.preventDefault();
			adID.value = '';
			searchFormSubmit(false, true, true);
		});
	});

	filterLocation.forEach((type) => {
		type.addEventListener('change', (e) => {
			e.preventDefault();
			adID.value = '';
			searchFormSubmit(false, true, true);
		});
	});

	statusType.forEach((type) => {
		type.addEventListener('change', (e) => {
			e.preventDefault();
			adID.value = '';
			searchFormSubmit(false, true, true);
		});
	});

	sortType.forEach((type) => {
		type.addEventListener('click', () => {
			sendSortGTM(type.dataset.label);
		});
	});

	const showFilterButtons = document.querySelectorAll('.js-show-filters');
	const FILTERS_SHOWN = 'js-filters-shown';
	showFilterButtons.forEach((button) => {
		button.addEventListener('click', () => {
			stickFilter();

			// eslint-disable-next-line no-param-reassign
			button.style.position	= 'absolute';
			// eslint-disable-next-line no-param-reassign
			button.style.top		= 'unset';
			// eslint-disable-next-line no-param-reassign
			button.style.bottom		= '0px';
			// eslint-disable-next-line no-param-reassign
			button.style.left		= '50%';

			button.classList.add(FILTERS_SHOWN);
			button.classList.add('filter__show-btn--expanded');
		});
	});

	const priceSlider = document.querySelector('.js-range-slider');
	const bubbleFirst = document.createElement('output');
	const bubbleSecond = document.createElement('output');
	bubbleFirst.classList.add('range-slider-output');
	bubbleSecond.classList.add('range-slider-output');

	let inputFirst;
	let inputSecond;

	const bars = document.querySelectorAll('.js-bar');
	const prices = [];

	bars.forEach((bar) => {
		prices.push(bar.dataset.price);
	});

	function checkData(ranger) {
		const value = ranger.value();
		const newpriceFrom = value[0];
		const newpriceTo = value[1];

		priceFrom.value = prices[newpriceFrom];
		priceTo.value = prices[newpriceTo];
	}

	function setBubble(val, input, bubble) {
		// eslint-disable-next-line no-param-reassign
		bubble.innerHTML = `${prices[val]} Kč`;

		// eslint-disable-next-line no-param-reassign
		bubble.style.left = `calc(${input.style.left} - 28px)`;
	}

	const absoluteMin = priceSlider.getAttribute('data-min-value');
	const absoluteMax = priceSlider.getAttribute('data-max-value');

	const ranger = rangeSlider(priceSlider, {
		min: absoluteMin,
		max: absoluteMax,
		value: [
			priceFrom.value !== '' ? prices.indexOf(priceFrom.value) : absoluteMin,
			priceTo.value !== '' ? prices.indexOf(priceTo.value) : absoluteMax,
		],
		step: 1,
		rangeSlideDisabled: true,
		onInput: () => {
			const value = ranger.value();
			const newpriceFrom = value[0];
			const newpriceTo = value[1];

			setBubble(newpriceFrom, inputFirst, bubbleFirst);
			setBubble(newpriceTo, inputSecond, bubbleSecond);
			checkData(ranger);
		},
		onThumbDragEnd: () => {
			checkData(ranger);
			searchFormSubmit(false, true, true);
		},
	});

	const inputs = priceSlider.querySelectorAll('[role="slider"]');
	// eslint-disable-next-line prefer-destructuring
	inputFirst = inputs[0];
	// eslint-disable-next-line prefer-destructuring
	inputSecond = inputs[1];

	inputFirst.append(bubbleFirst);
	inputSecond.append(bubbleSecond);

	const value = ranger.value();
	const newpriceFrom = value[0];
	const newpriceTo = value[1];

	setBubble(newpriceFrom, inputFirst, bubbleFirst);
	setBubble(newpriceTo, inputSecond, bubbleSecond);
}

function checkSessionStorage() {
	if (
		typeof (sessionStorage) !== 'undefined'
		&& sessionStorage.getItem('backBtnClicked') === 'true'
		&& typeof (sessionStorage.getItem('mapPrevCenter')) !== 'undefined'
		&& sessionStorage.getItem('mapPrevCenter') !== null
		&& typeof (sessionStorage.getItem('mapPrevZoom')) !== 'undefined'
		&& sessionStorage.getItem('mapPrevViewport') !== null
		&& typeof (sessionStorage.getItem('mapPrevViewport')) !== 'undefined'
	) {
		searchViewport.value = sessionStorage.getItem('mapPrevViewport');
		const prevCenterObj = sessionStorage.getItem('mapPrevCenter').split(', ');
		const prevCenterX = prevCenterObj[0];
		const prevCenterY = prevCenterObj[1];
		const prevCenter = SMap.Coords.fromWGS84(prevCenterX, prevCenterY);
		const prevZoom = parseInt(sessionStorage.getItem('mapPrevZoom'), 10);
		map.setCenterZoom(prevCenter, prevZoom);
		sessionStorage.setItem('backBtnClicked', false);
	} else if (markersCoords.length !== 0) {
		searchViewport.value = sessionStorage.getItem('mapPrevViewport');
		const [newCenter, newZoom] = map.computeCenterZoom(markersCoords);
		map.setCenterZoom(newCenter, newZoom);
	}
}

const geoOptions = {
	enableHighAccuracy: true,
};

const fields = {
	stre: {
		key: 'street',
		filter: '^ulice ',
	},
	ward: {
		key: 'ward',
		filter: '^(čtvrť|část obce) ',
	},
	muni: {
		key: 'city',
	},
	dist: {
		key: 'district',
		filter: '^okres ',
	},
	regi: {
		key: 'region',
		filter: '^kraj ',
	},
};

function initGeo() {
	const searchType = document.querySelector('.js-search');
	const locateMe = document.querySelector('.js-locate-me');
	const searchInput = document.querySelector('.js-search-input');
	const adID = document.querySelector('.js-search-adId');

	const geoLocate = (geocoder) => {
		const results = geocoder.getResults();
		const data = {};
		Object.keys(results.items).forEach((key, i) => {
			if (Object.prototype.hasOwnProperty.call(results.items, i)) {
				const item = results.items[i];
				const field = fields[item.type];
				if (field) {
					let value = item.name;

					if (field.filter) {
						value = value.replace(new RegExp(field.filter, 'i'), '');
					}

					data[field.key] = value;
				}
			}
		});
		searchInput.value = data[Object.keys(data)[0]];
		searchLon.value = results.coords.x;
		searchLat.value = results.coords.y;
		searchType.value = JSON.stringify(data);
		adID.value = '';
		searchFormSubmit(true, true);
	};

	const geoLocateSuccess = (position) => {
		const coords = SMap.Coords.fromWGS84(position.coords.longitude, position.coords.latitude);

		// eslint-disable-next-line no-new
		new SMap.Geocoder.Reverse(coords, geoLocate);
	};

	if (locateMe) {
		locateMe.addEventListener('click', (e) => {
			e.preventDefault();
			navigator.geolocation.getCurrentPosition(geoLocateSuccess, null, geoOptions);
		});
	}

	function processData(data) {
		const { secondRow } = data;
		const { source } = data;
		const { title } = data;
		const dataJSON = {};
		const moreData = secondRow.split(', ');
		let type = '';
		adID.value = '';

		switch (source) {
			case 'stre':
				[, dataJSON.city] = moreData;
				dataJSON.region = moreData.length === 4
					? moreData[2].replace(/^kraj /i, '')
					: '';
				dataJSON.street = title;
				break;
			case 'quar':
				[, dataJSON.city] = moreData;
				dataJSON.region = moreData.length === 4
					? moreData[2].replace(/^kraj /i, '')
					: '';
				type = source === 'quar' ? 'quarter' : 'ward';
				dataJSON[type] = title;
				break;
			case 'ward':
				[, dataJSON.city] = moreData;
				dataJSON.region = moreData.length === 4
					? moreData[2].replace(/^kraj /i, '')
					: '';
				type = source === 'quar' ? 'quarter' : 'ward';
				dataJSON[type] = title;
				break;
			case 'muni':
				dataJSON.city = title;
				dataJSON.region = moreData.length === 3
					? moreData[1].replace(/^kraj /i, '')
					: '';
				break;
			case 'dist':
				dataJSON.district = title.replace(/^kraj /i, '');
				dataJSON.region = moreData[1].replace(/^kraj /i, '');
				break;
			case 'regi':
				dataJSON.region = title.replace(/^kraj /i, '');
				break;
			default:
				break;
		}

		searchLon.value = data.longitude;
		searchLat.value = data.latitude;
		searchType.value = JSON.stringify(dataJSON);
		const filterRegion = document.querySelectorAll('.js-filter-region');
		filterRegion.forEach((region) => {
			region.removeAttribute('checked');
		});

		searchFormSubmit(true, true, false, true);
		suggesting	= true;
	}
	if (searchInput) {
		/* eslint-disable no-param-reassign, arrow-parens */
		const suggest = new SMap.Suggest(searchInput, {
			provider: new SMap.SuggestProvider({
				updateParams: (params) => {
					params.category = 'district_cz,municipality_cz,quarter_cz,ward_cz,street_cz';
					params.lang = 'cs';
					params.locality = 'cz';
					params.enableCategories = 1;
				},
			}),
		});
		/* eslint-enable */

		suggest.addListener('enter', () => {
			if (!suggest.isActive()) {
				suggest.send();
				suggest.open();

				const suggestList = document.querySelector('.smap-suggest');
				if (suggestList && suggestList.firstElementChild) {
					suggestList.firstElementChild.click();
				}
			}

			if (searchInput.value === '') {
				resetSearch();
			}
		});

		searchInput.addEventListener('click', () => {
			if (!suggest.isActive()) {
				suggest.send();
				suggest.open();
			}
		});

		suggest.addListener('suggest', (suggestData) => {
			processData(suggestData.data);
		});
	}
}

// Keep viewport when switching between various view types that hard reload page
function addViewSwitchesZoomListener(element) {
	if (typeof (sessionStorage) !== 'undefined') {
		const viewSwitches = element.querySelectorAll('.js-keep-viewport');
		viewSwitches.forEach((viewSwitch) => {
			viewSwitch.addEventListener('click', (e) => {
				e.preventDefault();
				sessionStorage.setItem('backBtnClicked', true);
				window.location = viewSwitch.href;
			});
		});
	}
}

function initFavoriteSyncButtons() {
	const favoriteSyncButtons = document.querySelectorAll('.js-favorite-sync');
	favoriteSyncButtons.forEach((button) => {
		button.removeEventListener('click', () => syncFavorite(button, true));
		button.addEventListener('click', () => syncFavorite(button, true));
	});
}

addViewSwitchesZoomListener(document);

let positionIndex = 0;

function loadMore() {
	const products = Array.from(document.querySelectorAll('.js-map-card'));
	const items = [];
	(positionIndex !== 0 ? (products.slice(positionIndex, products.length)) : (products)).forEach((product) => {
		items.push({
			name: product.dataset.name,
			id: product.dataset.id,
			price: product.dataset.price,
			brand: product.dataset.type,
			list: 'Vyhledávání',
			position: positionIndex + 1,
		});
		positionIndex += 1;
	});
	if (gtmLoaded) {
		dataLayer.push({
			event: 'gaEeImpressions',
			ecommerce: {
				currencyCode: 'CZK',
				impressions: items,
			},
		});
	}
}

let shouldLoadMore = false;

function gtmInit() {
	const loadMoreButton = document.querySelector('.js-load-more');
	if (loadMoreButton) {
		loadMoreButton.addEventListener('click', () => {
			shouldLoadMore = true;
			showLoader();
		});
	}

	const zoomOutButton = document.querySelector('.js-zoom-out');
	if (zoomOutButton) {
		zoomOutButton.addEventListener('click', () => {
			const url = zoomOutButton.dataset.url.replace('__viewport__', searchViewport.value);
			naja.makeRequest('POST', url, null, { history: false, unique: 'zoomOut' }).then((payload) => {
				lastRequestZoomOut = true;
				zoomToPayload(payload);
			});
		});
	}

	const nextPageButton = document.querySelector('.js-next-page');
	if (nextPageButton) {
		nextPageButton.addEventListener('click', () => {
			const { limit } = nextPageButton.dataset;
			searchLimit.value = limit;
			searchFormSubmit(false, true, true);
		});
	}

	const otherNextPageButton = document.querySelector('.js-other-next-page');
	if (otherNextPageButton) {
		otherNextPageButton.addEventListener('click', () => {
			const { limit } = otherNextPageButton.dataset;
			otherSearchLimit.value = limit;
			searchFormSubmit(false, true, true);
		});
	}
}

function markerAnimation() {
	const cards = document.querySelectorAll('.js-map-card');

	if (cards) {
		cards.forEach((card) => {
			card.addEventListener('mouseenter', () => {
				const mapMarker = document.querySelector(`.map__marker[title="${card.dataset.id}"]`);
				if (mapMarker) {
					mapMarker.classList.add('is-hover');
				}
			});
			card.addEventListener('mouseleave', () => {
				const mapMarker = document.querySelector(`.map__marker[title="${card.dataset.id}"]`);
				if (mapMarker) {
					mapMarker.classList.remove('is-hover');
				}
			});
		});
	}
}

naja.snippetHandler.addEventListener('beforeUpdate', (event) => {
	const { snippet, options } = event.detail;

	if (snippet.id === 'snippet--searchForm') {
		if (isFilterSticky()) {
			unstickFilter();
			options.filterSticky = true;
		}
	}
	if (snippet.id === 'snippet--adPlaceList' || snippet.id === 'snippet--otherAdPlaceList') {
		if (shouldLoadMore) {
			options.loadMore = true;
		}
	}
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
	const { snippet, options } = event.detail;
	if (snippet.id === 'snippet--searchForm') {
		updateMinimized();
		init();
		initGeo();

		if (options.filterSticky === true) {
			setTimeout(stickFilter, 100);
		}

		// Update all backlinks on the page with new value from search snippet
		const filterContainer = document.querySelector('.filter__container');
		const allLinks = document.querySelectorAll('a.product__img-link, a.product__link');
		allLinks.forEach((linkElement) => {
			linkElement.setAttribute('href', linkElement.href.substring(0, linkElement.href.length - 5) + filterContainer.dataset.back);
		});
	}

	if (snippet.id === 'snippet--adPlaceList' || snippet.id === 'snippet--otherAdPlaceList') {
		if (options.loadMore === true) {
			loadMore();
		}

		hideLoader();
		cardActive();
		cardBorders();
		gtmInit();
		markerAnimation();
	}

	addViewSwitchesZoomListener(snippet);
	initFavoriteSyncButtons();
});

init();
initGeo();
initLoader();
initFavoriteSyncButtons();
gtmInit();
markerAnimation();
checkSessionStorage();

document.addEventListener('GTMload', () => {
	loadMore();
	dataLayer.push({ pageType: 'search' });
});
