/* global document */

export function setCookie(name, value) {
	const date = new Date();
	date.setTime(date.getTime() + (4 * 60 * 60 * 1000));

	function escape(s) {
		return s.replace(/([.*+?$(){}|\\])/g, '\\$1');
	}

	const match = document.cookie.match(RegExp(`(?:^|;\\s*)${escape(name)}=([^;]*)`));
	if (match) {
		if (!match[1].includes(value)) {
			const oldVal = match[1];
			document.cookie = `${name}=${oldVal},${value}; path=/; Secure; expires=${date.toUTCString()};`;
		}
	} else {
		document.cookie = `${name}=${value}; path=/; Secure; expires=${date.toUTCString()};`;
	}
}

export function getCookie(name) {
	function escape(s) { return s.replace(/([.*+?$(){}|\\])/g, '\\$1'); }
	const match = document.cookie.match(RegExp(`(?:^|;\\s*)${escape(name)}=([^;]*)`));
	return match ? match[1].split(',').sort() : [];
}
